import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TaggsDesktop, TaggsMobile } from '../../components/taggs';
import { DEVICE_TYPE_MOBILE } from '../../utils/constants/tabbedCarousel';
import { trackEvent } from '../../utils/tracking';
import tracks from '../../utils/tracks';

const TaggedCarouselWrapper = ({
  Carousel,
  propsCarousel,
  shopType = '',
  device = '',
  siteId,
  isFreeCarousel,
}) => {
  const {
    tabs: tags,
    strategy = '',
    title = '',
  } = propsCarousel;
  const [selectedTag, setSelectedTag] = useState(tags[0]);
  const namespace = 'ui-ms-carousel-tags';
  const isMobile = device === DEVICE_TYPE_MOBILE;
  const Taggs = isMobile ? TaggsMobile : TaggsDesktop;
  const titleTag = isMobile ? '' : title;
  const sendTrackTagCarousel = (tag) => {
    trackEvent({
      ...tracks.pillsLanding,
      melidata_event: {
        ...tracks.pillsLanding.melidata_event,
        event_data: {
          ...tracks.pillsLanding.melidata_event.event_data,
          tag_choice_id: tag.id,
          tag_choice_label: tag.label,
        },
      },
    });
  };
  const handleToggleCarousel = (tag) => {
    setSelectedTag(tag);
    sendTrackTagCarousel(tag);
  };
  const CarouselItems = () => {
    const {
      polycard_context: polycardContextTag,
      polycards: polycardsTag,
      view_more_action: viewMoreAction,
    } = selectedTag;
    return (
      <Carousel
        {...propsCarousel}
        polycards={polycardsTag}
        shopType={shopType}
        device={device}
        viewMoreAction={viewMoreAction}
        polycardContext={polycardContextTag}
        siteId={siteId}
        isFreeCarousel={isFreeCarousel}
        strategy={strategy}
        title={titleTag}
      />
    );
  };

  return (
    <div className={namespace}>
      <Taggs
        title={title}
        tags={tags}
        toggleCarousel={handleToggleCarousel}
        carouselActive={selectedTag.id}
        viewMoreAction={selectedTag.view_more_action}
      />
      <CarouselItems />
    </div>
  );
};

TaggedCarouselWrapper.propTypes = {
  Carousel: PropTypes.func.isRequired,
  propsCarousel: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        polycards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        polycard_context: PropTypes.shape({}).isRequired,
        view_more_action: PropTypes.shape({}).isRequired,
      }),
    ).isRequired,
    strategy: PropTypes.string,
    viewMoreAction: PropTypes.shape({}),
    style_component: PropTypes.string,
    title: PropTypes.string,
  }),
  shopType: PropTypes.string,
  device: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  isFreeCarousel: PropTypes.bool.isRequired,
};

export default TaggedCarouselWrapper;
